<template>
  <ll-modal :config="modalExtraConfig">
    <template #content>
      <div class="box">
        <span>Desideri attivare delle coperture assicurative?</span>

        <div class="radio-button">
          <ll-radio :config="radioConfig"></ll-radio>
        </div>
      </div>

      <div class="box">
        <span>
          Sulla base delle informazioni messe a disposizione, ritieni adeguate
          alle tue esigenze le coperture assicurative prescelte?
        </span>

        <div class="radio-button">
          <ll-radio :config="radioConfig2"></ll-radio>
        </div>
      </div>

      <div class="box">
        <span>
          Per procedere è necessario prendere visione dei documenti di seguito:
        </span>

        <ul>
          <li>
            <a href="#">Set informativo</a>
          </li>
          <li>
            <a href="#">Allegati 3 e 4</a>
          </li>
        </ul>
      </div>

      <div class="box">
        <span>
          Procedendo confermi la correttezza dei tuoi dati anagrfici e di voler
          quindi procedere con l’acquisto della polizza che ti verrà addebitata.
        </span>
      </div>
    </template>
    <template #footer>
      <ll-button
        @click="closeExtraModal()"
        buttonText="Annulla"
        classList="mr-4"
      ></ll-button>
      <ll-button
        @click="closeExtraModal()"
        buttonText="Conferma"
        classList="is-link"
      ></ll-button>
    </template>
  </ll-modal>
  <!-- button class="lpm-container" @click="showModalExtra()">
    <div class="lpm-icon">
      <ll-icon icon-name="comment"></ll-icon>
    </div>
  </button -->
</template>

<script>
import LlModal from "@wsi/ui-kit/LlModal";
import LlButton from "@wsi/ui-kit/LlButton";
import LlRadio from "@wsi/ui-kit/LlRadio";


export default {
  name: "LPM",
  components: {
    //LlIcon,
    LlModal,
    LlButton,
    LlRadio
  },
  data() {
    return {
      modalExtraConfig: {
        isVisible: false,
        closeButton: false,
        footer: {
          css: "is-justify-content-flex-end"
        }
      },
      radioConfig: {
        selected: null,
        id: "group-id",
        css: "additional-css",
        elements: [
          {
            value: true,
            label: "Si"
          },
          {
            value: false,
            label: "No"
          }
        ]
      },
      radioConfig2: {
        selected: null,
        id: "group-id",
        css: "additional-css",
        elements: [
          {
            value: true,
            label: "Si"
          },
          {
            value: false,
            label: "No"
          }
        ]
      }
    };
  },
  provide: {
    hideModal() {
      this.config.isVisible = false;
    }
  },
  methods: {
    closeExtraModal() {
      this.modalExtraConfig.isVisible = false;
    },
    showModalExtra() {
      this.modalExtraConfig.isVisible = true;
    }
  }
};
</script>

<style lang="scss" scoped></style>
