<template>
  <section class="area-personale">
    <div class="columns is-multiline is-centered">
      <div class="column is-12">
        <main-logo></main-logo>
      </div>

      <div class="column is-10">
        <div class="columns">
          <div class="column is-8">
            <section-nav :config="navConfig"></section-nav>
          </div>
        </div>
      </div>

      <div class="content-container">
        <div class="columns is-centered">
          <div class="column is-10">
            <div class="columns">
              <div class="column is-8">
                <section-polizze></section-polizze>
              </div>
              <div class="column is-4">
                <box-contattaci :config="contactsConfig"></box-contattaci>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <LPM></LPM>
  </section>
</template>

<script>
import SectionPolizze from "../components/area-personale/SectionPolizze.vue";
import sectionNav from "../components/area-personale/SectionNav.vue";
import MainLogo from "../components/base/MainLogo.vue";
import BoxContattaci from "../components/area-personale/BoxContattaci.vue";
import LPM from "../components/base/LPM.vue";

export default {
  name: "AreaPersonale",
  components: { SectionPolizze, sectionNav, MainLogo, BoxContattaci, LPM },
  data() {
    return {
      navConfig: {
        items: [
          { label: "Polizze", to: "#" },
          { label: "Sinistri", to: "#" },
          { label: "I tuoi dati", to: "#" }
        ]
      },
      contactsConfig: {
        contacts: [
          {
            label: "Numero Verde",
            details: "800085555",
            imgPath: require("../assets/images/phone.svg")
          },
          {
            label: "Dall’estero o da mobile",
            details: "0200638058",
            imgPath: require("../assets/images/smartphone.svg")
          },
          {
            label: "Fax",
            details: "0272010006",
            imgPath: require("../assets/images/fax.svg")
          },
          {
            label: "Email",
            details: "assistenza.clienti@wefox.it",
            imgPath: require("../assets/images/mail.svg")
          },
          {
            label: "9.00-12.30",
            details: "15.00-17.30",
            imgPath: require("../assets/images/clock.svg")
          }
        ]
      }
    };
  }
};
</script>

<style lang="scss">
.content-container {
  background: #eee;
  padding: 3em 0;
  position: relative;
  width: 100%;
}
</style>
