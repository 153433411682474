import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from './store'

import './assets/scss/style.scss'

/*function fetchCfgData() {
    return fetch('/cfg', {
      method: 'get',
      headers: {
        'content-type': 'application/json'
      }
    })
}*/

//(async  () => {
//    const cfg = await fetchCfgData();
//    axios.defaults.baseURL = cfg.BASE_URL
    createApp(App)
        .use(router)
        .use(store)
        .use(VueAxios, axios)
        .mount('#app')
//})();
