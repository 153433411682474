<template>
  <h1>About Page</h1>
</template>

<script>
  export default {
    mounted() {
      console.debug("mounted",this.$http.get("https://api.thecatapi.com/v1/images/search"))

      this.$http.get("https://api.thecatapi.com/v1/images/search").then((response) => {
        console.log(response.data)
      })
    }
  }
</script>