<template>
  <div class="main-footer">
    <div class="footer-links">
      <div class="columns">
        <div
          class="column is-one-fifth"
          v-for="(section, i) in config.footerSections"
          :key="i"
        >
          <ul class="links">
            <li v-for="(link, e) in section" :key="e">
              <a :href="link.to">
                <ll-icon
                  v-if="link.iconName"
                  :icon-name="link.iconName"
                ></ll-icon>
                <div class="img-container" v-if="link.imgPath">
                  <img :src="link.imgPath" alt="" />
                </div>
                {{ link.label }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="footer-text">
      <p>
        wefox MGA s.r.l. - Via Roberto Bracco, 6 - 20159 - 
        TEL. 02 30465068 FAX 02 62694254 - Email PEC: wefoxmga@registerpec.it - CCIAA Milano - REA
        MI 1908726 - C.F., P. IVA e Reg. Imprese Milano 06680830962 - Capitale
        sociale 100.000 EURO - Registro Unico Intermediari A000317603 - iscritta
        dal 15 settembre 2009 e sottoposta alla vigilanza dell'IVASS - I dati
        sono consultabili nel Registro RUI al seguente link:
        <a
          href="https://servizi.ivass.it/RuirPubblica"
          style="color: inherit; text-decoration: underline;"
          >https://servizi.ivass.it/RuirPubblica</a
        >
        - Banco Posta Sede di Milano, Piazza Cordusio, 1
      </p>
      <ul>
        <li><a href="#">Privacy</a></li>
        <li>
          <a href="#">Imprese di assicurazioni con cui operiamo e reclami</a>
        </li>
        <li><a href="#">Modello Organizzativo D.Lgs. 231/01</a></li>
        <li><a href="#">Codice Etico</a></li>
        <li><a href="#">Trasparenza</a></li>
      </ul>
    </div>
  </div>
</template>

<script>
import LlIcon from "@wsi/ui-kit/LlIcon";

export default {
  name: "MainFooter",
  components: { LlIcon },
  props: {
    config: {
      footerSections: Object
    }
  }
};
</script>

<style lang="scss" scoped></style>
