<template>
  <nav v-if="$route.path.search('\/polizza\/') > 0">
    <div class="columns is-centered">
      <div class="column is-10">
        <ll-menubar :config="menuConfig">
          <div class="navbar-item has-dropdown is-hoverable reserved-area">
            <router-link class="navbar-link" to="/area-personale">
              <ll-icon icon-name="person"></ll-icon>&nbsp; Area Personale
            </router-link>

            <div class="navbar-dropdown">
              <ll-button button-text="LogOut"></ll-button>
            </div>
          </div>
        </ll-menubar>
      </div>
    </div>
  </nav>

  <router-view />

  <footer v-if="$route.path.search('\/polizza\/') > 0">
    <div class="columns is-centered">
      <div class="column is-10">
        <main-footer :config="footerConfig"></main-footer>
      </div>
    </div>
  </footer>
</template>

<script>
import LlMenubar from "@wsi/ui-kit/LlMenubar";
import LlIcon from "@wsi/ui-kit/LlIcon";
import LlButton from "@wsi/ui-kit/LlButton";
import MainFooter from "./components/base/MainFooter.vue";

export default {
  name: "App",
  components: { LlMenubar, MainFooter, LlIcon, LlButton },
  data() {
    return {
      menuConfig: {
        items: [
          {
            label: "Chi Siamo",
            to: "/",
            icon: "info",
            children: [
              { label: "Dove siamo", to: "#" },
              { label: "Il Team Sinistri", to: "#" }
            ]
          },
          {
            label: "I Nostri Numeri",
            to: "/",
            icon: "graph"
          },
          {
            label: "Servizio Clienti",
            to: "/",
            icon: "people",
            children: [{ label: "FAQ", to: "#" }]
          },
          { label: "Servizi Online", to: "/", icon: "rss" }
          // {
          //   label: "Polizza",
          //   to: {
          //     name: "Polizza",
          //     params: {
          //       cf_piva: "03392091207",
          //       compagnia: "A08",
          //       numero_polizza: "00000004830199"
          //     }
          //   }
          // },
          // {
          //   label: "Lista Polizze",
          //   to: {
          //     name: "ListaPolizze",
          //     params: { cf_piva: "GGNMRC73R03H501O" }
          //   }
          // }
        ]
      },
      footerConfig: {
        footerSections: {
          section1: [
            {
              label: "Home",
              to: "#",
              iconName: "home"
            }
          ],
          section2: [
            { label: "Chi Siamo", to: "#", iconName: "info" },
            { label: "Dove Siamo", to: "#", iconName: "location" },
            { label: "Il Team Sinistri", to: "#", iconName: "person" }
          ],
          section3: [{ label: "I Nostri Numeri", to: "#", iconName: "graph" }],
          section4: [
            { label: "Info Clienti", to: "#", iconName: "people" },
            { label: "FAQ", to: "#", iconName: "question" }
          ],
          section5: [{ label: "Servizi Online", to: "#", iconName: "rss" }]
        }
      }
    };
  }
};
</script>

<style lang="scss">
#app {
  font-family: "Pluto";
}
</style>
