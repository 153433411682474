// https://www.vuemastery.com/blog/vue-router-a-tutorial-for-vue-3/

import { createWebHistory, createRouter } from "vue-router";

import Test from "@/views/Test.vue";
//import Home from "@/views/Home.vue";
import About from "@/views/About.vue";
import Components from "@/views/Components.vue";
import Polizza from "@/views/Polizza.vue";
import PolizzaVW from "@/views/PolizzaVW.vue";
import PolizzaOPEL from "@/views/PolizzaOPEL.vue";
import ListaPolizze from "@/views/ListaPolizze.vue";
import AreaPersonale from "@/views/AreaPersonale.vue";
import ReclamiVW from "@/views/ReclamiVW.vue";


const routes = [
  {
    path: "/",
    redirect: "/test"
    //name: "Home",
    //component: Home,
  },
  {
    path: "/test",
    name: "Test",
    component: Test,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/components",
    name: "Components",
    component: Components,
  },
  {
    path: "/area-personale",
    name: "AreaPersonale",
    component: AreaPersonale,
  },
  {
    path: "/user/:cf_piva/polizza/:compagnia/:numero_polizza",
    name: "Polizza",
    component: Polizza,
  },
  {
    path: "/user/:cf_piva/polizza-vw/:compagnia/:numero_polizza",
    name: "PolizzaVW",
    component: PolizzaVW,
  },
  {
    path: "/user/:cf_piva/polizza-opel/:compagnia/:numero_polizza",
    name: "PolizzaOPEL",
    component: PolizzaOPEL,
  },
  {
    path: "/user/:cf_piva/polizze",
    name: "ListaPolizze",
    component: ListaPolizze,
  },
  {
    path: "/reclamiVW",
    name: "ReclamiVW",
    component: ReclamiVW,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;