<template>
  <br>  
  <div class="columns">
    <div class="column is-2">
      <li v-for="item in lista" :key="item.polizza.numero_polizza">
        <input type="checkbox" :checked="item.selected" @change="check(item.polizza.numero_polizza)">
          {{ item.selected }} {{ item.polizza.cliente }} | {{ item.polizza.numero_polizza }}
        <router-link :to="{ name: 'Polizza', params: { cf_piva: '03392091207', compagnia: item.compagnia, numero_polizza: item.polizza.numero_polizza}}">Polizza</router-link>
      </li>    
    </div>
    <div class="column is-2">
    </div>
  </div>
  <div class="columns">
    <div class="column is-2">
    </div>
    <div class="column is-2">
    </div>
  </div>
</template>

<script>


export default {
  data () {
    return {
      polizza: {
        cliente: "test"
      }
    }
  },

  async mounted() {
    console.debug("mounted")
    this.$store.dispatch('load', this.$route.params)
  },

  computed: {
    count () {
      return this.$store.state.count
    },
    lista () {
      return this.$store.state.lista
    }
  },

  methods: {
    check(numero_polizza) {
      console.log(numero_polizza);
      this.$store.commit('check', numero_polizza)
    }
  }
  
}
</script>