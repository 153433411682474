<template>
  <div class="logo-container">
    <div class="main-logo">
      <a href="/">
        <img src="@/assets/images/mach1-logo.png" alt="" class="s" />
      </a>
      <h2 class="title-payoff">
        <span>LEADER NEI SERVIZI ASSICURATIVI</span>
      </h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "Logo"
};
</script>

<style lang="scss"></style>
