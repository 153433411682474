<template>
  <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>
  <div class="columns is-multiline is-centered">
    <div class="column is-10">

      <!-- LOGO -->
      <div class="columns" >

          <div class="column" style="padding 10px;">
          <ul>
            <li class="main_li" style="float: left;"><b>CONTATTI</b>
              <ul class="dropdown" aria-label="submenu" style="text-align: center;left:66%;right:unset !important;">
                <li style="padding: 30px 20px 0px 20px; color:#000;">
                  <i class="far fa-phone"></i> &nbsp;  02.3661714<hr class="my_hr">
                  <i class="fal fa-phone-plus"></i> &nbsp; 848.800.044<hr class="my_hr">
                  <i class="fal fa-envelope"></i> &nbsp; assistenzaofs@mach-1.it<hr class="my_hr">
                </li>
              </ul>
            </li>
          </ul>
        </div> 
        
        <div class="column is-4" style="text-align:right;">
          <div class="logo-img">
            <img src="@/assets/images/A55.png" />
          </div>
        </div>



      </div>

      <!-- TITLE POLIZZA -->
      <div class="columns my_title" style="margin-top:100px">
        <div class="column is-8">
          <b>Dati polizza</b>
        </div>
      </div>

      <!-- WARNINGS MESSAGE -->
      <div v-show="errore != null" class="columns">
        <div class="column is-8">
          <div class="notification is-danger is-light">
            <button class="delete"></button>
            {{ errore }}
          </div>
        </div>
      </div>


      <!-------------------- START FORM -------------------->

      <!-- ROW 1 -->
      <div class="columns">
        <div class="column is-3">
          <label class="my_label" for="nome_cognome">Nome Cognome</label>
          <input readonly class="my_input" name="nome_cognome" v-model="polizza.cliente"/>
        </div>
        <div class="column is-3">
          <label class="my_label" for="email" :style="!polizza.email ? 'color:red':''">Email *</label>
          <input class="my_input" name="email" v-model.trim="polizza.email" :style="!polizza.email ? 'border-color:red':''"/>
        </div>
        <div class="column is-3">
          <label class="my_label" for="cellulare" :style="!polizza.cellulare ? 'color:red':''">Cellulare *</label>
          <input class="my_input" name="cellulare" v-model.trim="polizza.cellulare" :style="!polizza.cellulare ? 'border-color:red':''"/>
        </div>
        <div class="column is-3">
          <label class="my_label" for="telefono">Telefono *</label>
          <input class="my_input" name="telefono" v-model="polizza.telefono"/>
        </div>
      </div>

      <!-- ROW 2 -->
      <div class="columns">
        <div class="column is-3">
          <label class="my_label" for="indirizzo">Indirizzo</label>
          <input readonly class="my_input" name="indirizzo" v-model="polizza.indirizzo"/>
        </div>
        <div class="column is-3">
          <label class="my_label" for="citta">Località</label>
          <input readonly class="my_input" name="citta" v-model="polizza.citta"/>
        </div>
        <div class="column is-3">
          <div class="columns">
            <div class="column is-6">
              <label class="my_label" for="cap">CAP</label>
              <input readonly class="my_input input_mini" name="cap" v-model="polizza.cap"/>
            </div>
            <div class="column is-6">
              <label class="my_label" for="provincia">Provincia</label>
              <input readonly class="my_input input_mini" name="provincia" v-model="polizza.provincia"/>
            </div>
          </div>
        </div>
        <div class="column is-3">
          <label class="my_label" for="numero_polizza">Numero Polizza</label>
          <input readonly class="my_input" name="numero_polizza" v-model="polizza.numero_polizza"/>
        </div>
      </div>

      <!-- ROW 3 -->
      <div class="columns">
        <div class="column is-3">
          <label class="my_label" for="scadenza">Scadenza</label>
          <input readonly class="my_input" name="scadenza" v-model="polizza.scadenza"/>
        </div>
        <div class="column is-3">
          <label class="my_label" for="targa">Targa</label>
          <input readonly class="my_input" name="targa" v-model="polizza.targa"/>
        </div>
        <div class="column is-3">
          <label class="my_label" for="telaio">Telaio</label>
          <input readonly class="my_input" name="telaio" v-model="polizza.telaio"/>
        </div>
        <div class="column is-3">
          <label class="my_label" for="marca">Marca</label>
          <input readonly class="my_input" name="marca" v-model="polizza.marca"/>
        </div>
      </div>

      <!-- ROW 4 -->
      <div class="columns">
        <div class="column is-3">
          <label class="my_label" for="modello">Modello</label>
          <input readonly class="my_input" name="modello" v-model="polizza.modello"/>
        </div>
      </div>

      <!-------------------- END FORM -------------------->




      <!-- INFORMATION DISCLAIMER -->
      <div class="columns">
        <div class="column is-8">
          <span style="font-size: 12px; color: grey">Eventuali richieste di modifiche relative all'indirizzo di residenza o al veicolo, dovranno essere inviate a rinnovi.ofs@wefox.it</span>
        </div>
      </div>

      <!-- TITLE PACCHETTI -->
      <div class="columns my_title" style="margin-top:70px;">
        <div class="column is-8">
          <b>Seleziona il prodotto</b>
        </div>
      </div>



      <!-------------------- START PACCHETTI -------------------->

      <div v-for="(pairs, index) in pacchetti_pairs" :key="index" class="columns">
        <div v-for="(pacchetto, index2) in pairs" :key="index2" class="column is-3">
          <div @click="selectPacchetto(pacchetto.nome)" class="tooltip my_pack" :class="{ 'my_pack_selected': pacchetto_selected == pacchetto.nome }">
            
            <div class="columns" style="cursor: pointer;">
              <div class="column is-10">

                <!-- PACK TITLE -->
                <p style="font-size:12px; margin-bottom: 10px;">POLIZZA AUTO FURTO INCENDIO</p>
                
                <!-- PACK INFO -->
                <div class="tooltiptext italic">
                  la polizza offre copertura in caso di <i>{{ pacchetto.tooltip }}</i>
                </div>
                
                <!-- PACK NAME -->
                <p style="font-size:20px; font-weight:bold;">{{ capitalizeFirstLetter(pacchetto.nome) }}</p>

                <!-- PACK PRICE -->
                <p style="font-size:24px; font-weight:bold; margin-top:30px;">
                  {{ pacchetto.premio }}€
                </p>
              </div>  

              <!-- CHECK -->
              <div class="column is-2" style="padding: 10px 0;">
                <div class="my_selection_check">
                  {{ pacchetto_selected == pacchetto.nome ? "✔" : "" }}
                </div>
              </div>
            </div>

            <!-- HR LINE -->
            <hr style="border-top:2px solid" :style="{ borderColor: pacchetto_selected == pacchetto.nome ? '#000' : '#000' }">

            <!-- SET INFORMATIVO -->
            <p style="font-size:16px; font-weight:bold; text-align:center">Dettaglio coperture</p>
          
          </div>
        </div>
      </div>

      <!-------------------- END PACCHETTI -------------------->



      <!-- POLIZZA DESCRIPTION -->
      <div class="columns">
        <div class="column is-8">
          <i style="font-size: 13px">"{{ polizza.desc }}"</i>
        </div>
      </div>


      <!-- TOTAL PRICE -->
      <div  class="columns">
        <div class="column is-6"></div>
        <div class="column is-4 my_title" style="padding-left: 50px;">
          Premio Totale: <b>€ {{ premio_totale2 }} </b>
          <hr style="border-top: 2px solid #000">
          <p style="font-size: 14px; color: #A8ADB3">Cliccando su "RINNOVA" potrai completere il processo di acquisto della polizza selezionata</p>
        </div>
      </div>


      <!-- BUTTONS -->
      <div class="columns" style="margin-top:50px;">
        <div class="column is-4"></div>

        <!-- BUTTON ANNULLA -->
        <div class="column is-2">
          <button @click="annulla" class="button is-white my_button">ANNULLA</button>
        </div>
        <!-- BUTTON RINNOVA -->
        <div class="column is-2">
          <button @click="rinnova" :disabled="errore != null || premio_totale2 == 0 || polizza.email == '' || polizza.cellulare == ''" 
            class="button is-primary my_button" style="background-color:#f7ff12">RINNOVA</button>
        </div>

      </div>


      <!-- POP UP ADEGUATEZZA -->
      <div class="modal" :class="{ 'is-active': show_modal }">
        <div class="modal-background"></div>
        <div class="modal-content" style="background-color: white; font-weight: normal; padding: 40px;">

          <div class="column is-12">
            <div class="logo-popup">
              <img v-bind:src="this.url_logo_marchio" />
            </div>
          </div>

          <!-- DOMANDA 1 -->
          <p style="padding-top: 20px">
            1) Sei interessato alla protezione assicurativa per eventuali danni per danno totale o parziale subiti dal tuo veicolo?</p>
          <div class="control">
            <label class="radio"><input type="radio" name="answer1" value="true" v-model="answer1"> Si</label>
            <label class="radio"><input type="radio" name="answer1" value="false" v-model="answer1"> No</label>
          </div>

          <!-- DOMANDA 2 -->
          <p style="padding-top: 20px">
            2) Valuti che il premio proposto sia allineato con le tue capacità di spesa?</p>
          <div class="control">
            <label class="radio"><input type="radio" name="answer2" value="true" v-model="answer2"> Si</label>
            <label class="radio"><input type="radio" name="answer2" value="false" v-model="answer2"> No</label>
          </div>

          <!-- DOMANDA 3 -->
          <p style="padding-top: 20px">
            3) Confermi che la durata delle coperture assicurative proposte sia in linea con le tue aspettative?</p>
          <div class="control">
            <label class="radio"><input type="radio" name="answer3" value="true" v-model="answer3"> Si</label>
            <label class="radio"><input type="radio" name="answer3" value="false" v-model="answer3"> No</label>
          </div>

          <!-- DOMANDA 4 -->
          <p style="padding-top: 20px">
            4) Confermi di voler ricevere la documentazione via mail con supporto durevole?</p>
          <div class="control">
            <label class="radio"><input type="radio" name="answer4" value="true" v-model="answer4"> Si</label>
            <label class="radio"><input type="radio" name="answer4" value="false" v-model="answer4"> No</label>
            <p v-if="documento_cartaceo" style="padding-top:10px; padding-left:20px; font-size:80%">
              per ricevere la documentazione in formato cartaceo scrivere e-mail a rinnovi.ofs@mach-1.it</p>
          </div>

          <!-- DOMANDA 5 -->
          <p style="padding-top: 40px;font-weight:bold;">
            Hai preso visione dei documenti in seguito</p>
          <div class="control">
            <label class="radio"><input type="radio" name="answer5" value="true" v-model="answer5"> Si</label>
            <label class="radio"><input type="radio" name="answer5" value="false" v-model="answer5"> No</label>
          </div>
          <div class="content" style="margin: 20px">
              <a :href="pdf_fascicolo" style="color: #000 !important" target="_blank"> Set informativo</a> 
              <a :href="pdf_fascicolo" target="_blank" download style="color:black;float:right">
                <i class="fa fa-download" aria-hidden="true"></i>
              </a>
              <br>
              <a :href="pdf_informativa" style="color: #000 !important" target="_blank"> Informativa precontrattuale</a> (Alleg.3, 4, 4 ter) 
              <a :href="pdf_informativa" target="_blank" download style="color:black;float:right">
                <i class="fa fa-download" aria-hidden="true"></i>
              </a>
          </div>
          <p style="margin-top: 20px">Procedendo confermi la correttezza dei tuoi dati anagrafici e di voler quindi procedere con l'acquisto della polizza che ti verrà addebitata su carta di credito.</p>


          <!-- DOMANDA 6 -->
          <div v-if="ultima_domanda" style="padding-top: 30px">
            <p style="font-weight:bold;">
              Alla luce delle informazioni fornite, ritieni che il prodotto selezionato sia coerente con le tue esigenze?</p>
            <div class="control">
              <label class="radio"><input type="radio" name="answer6" value="true" v-model="answer6"> Si</label>
              <label class="radio"><input type="radio" name="answer6" value="false" v-model="answer6"> No</label>
            </div>
          </div>

          <!-- DOMANDA 7 -->
          <p style="margin: 45px 20px;font-weight: bold;background: #f7ff12;color:black;border-radius:10px; padding: 30px;">
            Per procedere con il rinnovo della tua polizza stai per essere reindirizzato al portale dei pagamenti di Mach1, agenzia partner di Opel Financial Services
          </p>

          <!-- MODAL BUTTONS -->
          <div class="columns" style="margin-top:50px;">
            <div class="column is-2"></div>

            <!-- BUTTON ANNULLA -->
            <div class="column is-4">
              <button @click="annullaAcquisto" class="button is-white my_button">Annulla</button>
            </div>
            <!-- BUTTON RINNOVA -->
            <div class="column is-4">
              <button @click="conferma" :disabled="conferma_disabled" class="button is-pulled-right is-primary my_button" style="background-color:#f7ff12" :class="{ 'is-loading': conferma_loading }">Conferma</button>
            </div>

          </div>
        </div>
        <button class="modal-close is-large" aria-label="close"></button>
      </div>
    </div>
  </div>

  <!-- FOOTER -->
  <div class="my_footer">
    <p>Opel Bank SA, Succursale Italiana, con sede in Roma, Via A. Millevoi 10 – 00178, (tel. +39 06 87422111 – fax +39 06 87422208), www.opelfinancialservices.it, numero di iscrizione presso il Registro delle Imprese di Roma, C.F. e P.IVA: 15386181000, numero REA: RM – 1587089; iscritta al numero 8085 all’Albo degli Istituti bancari, come succursale italiana della banca comunitaria Opel Bank SA, società di diritto francese, iscritta presso il Registro delle Imprese di Versailles al n. 562 068 684, avente sede a 2 Boulevard de l’Europe, 78300 Poissy (Francia), capitale sociale 101.929.642 €. Opel Bank SA, Succursale Italiana, è un intermediario assicurativo, iscritto nell’Elenco degli Intermediari dell’Unione Europea tenuto dall’IVASS con numero di matricola UE00011009, numero di registrazione nello stato d’origine: ORIAS N°07009184. Opel Financial Services è un nome commerciale di Opel Bank SA.</p>
  </div>

</template>

<script>

  export default {
    components: {},

    data () {
      return {
        errore: null,
        polizza: {},
        pacchetto_selected: null,
        show_modal: false,
        conferma_loading: false,
        conferma_done: false,
        answer1: "false",
        answer2: "false",
        answer3: "false",
        answer4: "true",
        answer5: "false",
        answer6: "false",
        check_pacchetto: false,
        select_pacchetto: true,
        select_index: 0,
        select_index2: 0,
        pdf_fascicolo: null,
        pdf_informativa: null      
      }
    },

    computed: {
      documento_cartaceo() {
        return (
          this.answer4 == 'false')
      },
      ultima_domanda() {
        return (
          this.answer1 == 'true' && 
          this.answer2 == 'true' && 
          this.answer3 == 'true' &&
          this.answer5 == 'true')
      },
      conferma_disabled() {
        return !(
          this.answer1 == 'true' && 
          this.answer2 == 'true' && 
          this.answer3 == 'true' &&
          this.answer5 == 'true' &&
          this.answer6 == 'true')
      },
      polizza_store() {
        return this.$store.state.polizza
      },
      pacchetti_pairs() {
        if (this.$store.state.polizza == null || this.$store.state.polizza.pacchetti == null || this.$store.state.polizza.length == 0) {
          return [];
        }
        return this.$store.state.polizza.pacchetti.reduce((result, value, index, array) => {
          if (index % 2 === 0) {
              result.push(array.slice(index, index + 2));
          }
          return result;
        }, []);
      },
      pacchetti() {
        if (this.$store.state.polizza == null || this.$store.state.polizza.pacchetti == null || this.$store.state.polizza.pacchetti.length == 0) {
          return [];
        }
        return this.$store.state.polizza.pacchetti;
      },
      premio_pacchetto() {
        if (!this.check_pacchetto) {
          return 0
        }
        console.log(this.pacchetto_selected)
        return this.pacchetti.find(pacchetto => pacchetto.nome == this.pacchetto_selected).premio
      },
      premio_pacchetto2() {
        if (!this.select_pacchetto || this.pacchetto_selected == null) {
          return 0
        }
        console.log(this.pacchetto_selected)
        return this.pacchetti.find(pacchetto => pacchetto.nome == this.pacchetto_selected).premio
      },
      pcl_pacchetto() {
        let pacchetto = this.pacchetti.find(pacchetto => pacchetto.nome == this.pacchetto_selected)
        if (pacchetto == null) {
          return 0;
        }
        return pacchetto.premio
      },
      premio_totale() {
        return this.premio_pacchetto
      },
      premio_totale2() {
        return this.premio_pacchetto2
      },
      route_params() {
        console.log(this.$route.params.compagnia.length)
        return {
          cf_piva: this.$route.params.compagnia.length > 3 ? atob(this.$route.params.cf_piva) : this.$route.params.cf_piva,
          compagnia: this.$route.params.compagnia.length > 3 ? atob(this.$route.params.compagnia) : this.$route.params.compagnia,
          numero_polizza: this.$route.params.compagnia.length > 3 ? atob(this.$route.params.numero_polizza) : this.$route.params.numero_polizza
        }
      }
    },

    methods: {
      selectIndexBox(index, index2, nome) {
        console.log("selectIndexBox: " + index + " " + index2 + " " + nome)
        this.pacchetto_selected = nome

        if (this.select_index != index || this.select_index2 != index2) {
          console.log("CLEAR")
          this.select_pacchetto = true
        }

        this.select_index = index
        this.select_index2 = index2
      },
      selectIndexCvt(index, index2) {
        console.log("selectIndexCvt: " +  this.select_pacchetto)
        this.select_index = index
        this.select_index2 = index2

        this.select_pacchetto = !this.select_pacchetto
      },
      selectPacchetto(nome) {
        console.log(nome)
        this.pacchetto_selected = nome
      },
      annulla() {
        this.$router.go(-1)
      },
      rinnova() {
        this.show_modal = true
        this.load_pdf_url()
      },
      async load_pdf_url() {
        let pacchetto = ''
        let params = this.route_params
        let compagnia = params.compagnia
        console.log("load_pdf_url - pacchetto_selected: " + this.pacchetto_selected)
        console.log("load_pdf_url - select_pacchetto: " + this.select_pacchetto)

        console.log("load_pdf_url - compagnia: " + compagnia)
        console.log("load_pdf_url - pacchetto: " + pacchetto)

        let response = await this.$http.get(`/api/v1/getPdf/${compagnia}/${pacchetto}`)
        console.log(response.data)

        this.pdf_fascicolo = response.data.fascicolo
        this.pdf_informativa = response.data.informativa
      },
      annullaAcquisto() {
        this.show_modal = false
        
        if (this.conferma_done) {
          this.$router.go()
        }
      },
      async conferma() {
        this.conferma_loading = true

        /* DOWNLOAD AUTOMATICO
        let download_fascicolo = this.pdf_fascicolo.replace('pdf', 'file-download/pdf')
        let download_informativa = this.pdf_informativa.replace('pdf', 'file-download/pdf') 
        */
                
        let params = this.route_params //this.$route.params

        // last update
        let email = this.polizza.email
        let cellulare = this.polizza.cellulare
        let telefono = this.polizza.telefono
        console.log(`conferma - email: ${email}`)
        console.log(`conferma - cellulare: ${cellulare}`)
        console.log(`conferma - telefono: ${telefono}`)

        this.polizza = this.$store.state.polizza
        
        // merge
        this.polizza.email = email
        this.polizza.cellulare = cellulare
        this.polizza.telefono = telefono
        
        let polizza = this.polizza

        if (this.select_pacchetto) {
          let response = await this.$http.put(
            `/api/v1/user/${params.cf_piva}/polizza/${params.compagnia}/${polizza.polizza_id}/rinnova`, {
              "polizza" : this.polizza, 
              "pacchetto_rinnovato" : this.pacchetto_selected,
              'pcl': this.pcl_pacchetto,
              'rinnovo' : {
                'cliente': this.polizza.cliente,
                'numero': this.polizza.numero_polizza,
                'compagnia': params.compagnia,
                'scadenza': this.polizza.scadenza,
                'invio_documenti': (this.answer4 === 'true'),
                'email': this.polizza.email,
                'da_email': true
              }
            }
          )
          console.log(response)
        }      
        
        this.conferma_loading = false
        this.conferma_done = true
        console.log(`https://pagamenti.mach-1.it/nais/login.php?codice=${params.cf_piva}&polizza=${polizza.numero_polizza}`)
        window.open(`https://pagamenti.mach-1.it/nais/login.php?codice=${params.cf_piva}&polizza=${polizza.numero_polizza}`, "_blank")
       
        /* DOWNLOAD AUTOMATICO
        window.open(download_fascicolo)
        window.open(download_informativa) 
        */
      },
      pacchetto_info(nome) {
        if (nome.indexOf("FIRST TRIP") > -1) {
          return "La polizza copre il veicolo in caso di furto, sia totale che parziale o anche tentato, incendio e rapina";
        } else if (nome.indexOf("BUSINESS TOUR") > -1) {
          return "La polizza copre il caso di furto, sia totale che parziale o anche tentato, incendio e rapina, atti vandalici, eventi naturali e rottura accidentale dei cristalli";
        } else if (nome.indexOf("CLUB VOYAGE COLLISIONE") > -1) {
          return "La polizza copre il caso di furto, sia totale che parziale o anche tentato, incendio e rapina, atti vandalici, eventi naturali, rottura accidentale dei cristalli e danni a seguito collisione tra veicoli";
        } else if (nome.indexOf("CLUB VOYAGE KASKO") > -1) {
          return "La polizza copre il caso di furto, sia totale che parziale o anche tentato, incendio e rapina, atti vandalici, eventi naturali, rottura accidentale dei cristalli e kasko"
        }
        return null;
      },
      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
    },

    async mounted() {
      console.debug("mounted")
      await this.$store.dispatch('loadPolizza', this.route_params /*this.$route.params*/)
      console.log('#######################' + JSON.stringify(this.$store.state.polizza))

      if (this.$store.state.polizza.errore != null) {
        this.errore = this.$store.state.polizza.errore
        return
      }

      this.polizza = this.$store.state.polizza

      if (this.polizza.pacchetti != null && this.polizza.pacchetti.length > 0) {
        this.pacchetto_selected = this.polizza.pacchetti[0].nome
      }
    },

    created() {
      let compagnia = this.$route.params.compagnia.length > 3 ? atob(this.$route.params.compagnia) : this.$route.params.compagnia;
      this.url_logo_marchio = require('@/assets/images/' + compagnia + '_small.png')
    }
  }
</script>

<style lang="scss" scoped>

// https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_tooltip

.tooltip {
  position: relative;
  // display: inline-block;
  // border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 100%;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding:20px;
  position: absolute;
  z-index: 1;
  bottom: 105%;
  left: 60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.logo-popup{
  text-align: right;
}

.logo-popup img{
  width: 200px;
  height: auto;
}

.logo-img img {
    width: auto;
}

.my_label {
    display:block;
    font-size: 12px;
    color: rgba(76, 83, 86, 0.5);
}

.my_input {
    margin-top: 15px;
    display:block;
    font-size: 16px;
    color: #4C5356;
    border:none;
    border-bottom: 1px solid #4C5356;
    outline: none;
    width: 90%;
    padding-bottom: 7px;
 }

.my_input[readonly] {
  cursor: not-allowed;
}

.input_mini {
  width: 80%;
}

.my_title {
  font-size: 30px;
  color: #000;
}

.my_blue_text {
  color: #000;
  font-weight: bold;
}

.my_pack {
  padding: 20px;
  background: #F2F2F2;
  color: #000;
  border: 1px solid #000;
}

.my_pack_selected {
  background-color: #f7ff12;
  color:black;
}

.my_selection_check {
  border:1px solid #000;
  height: 30px;
  width: 30px;
  background-color: #fff;border-radius: 16px;
  color: #000;
  text-align: center;
  font-size: 20px;
}

.my_button {
  width: 100%;
  color: #000 !important;
  border-radius: 0px !important;
  font-weight: bold;
}

.my_hr {
    border: none;
    height: 2px;
    color: #000; /* old IE */
    background-color: #f7ff12; /* Modern Browsers */
    padding: 0px;
    margin: 15px;    
}

li {
	color: #fff;
  background: #f7ff12;
	display: block;
	float: right;
	padding: 15px 15px 0 15px;
	position: relative;
	text-decoration: none;
  transition-duration: 0.5s;
}

.main_li{
  color: #000;
  background: #fff;
}

li:hover,
li:focus-within {
	cursor: pointer;
}

li:focus-within a {
  outline: none;
}

ul li ul {
  color: #fff;
	visibility: hidden;
  opacity: 0;
  min-width: 5rem;
	position: absolute;
  transition: all 0.5s ease;
  margin-top: 1rem;
	right: 0;
  display: none;
}

ul li:hover > ul,
ul li:focus-within > ul,
ul li ul:hover,
ul li ul:focus {
   visibility: visible;
   opacity: 1;
   display: block;
}

ul li ul li {
	clear: both;
  width: 130%;
}

.my_footer{
  margin-top:100px;
  padding: 20px;
  background-color: #f7ff12;
  color:black;
  font-size: 14px;
}

.button.is-primary.is-loading::after {
  border-color: transparent transparent #000 #000 !important;
}


</style>