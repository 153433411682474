<template>
  <div class="content">
    <span>
      Questa sezione è dedicata alle tue polizze. Puoi verificare le coperture,
      la scadenza e dove previsto scaricare i documenti.
    </span>
    <ll-input :config="inputConfig"></ll-input>
    <div class="block">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import LlInput from "@wsi/ui-kit/LlInput";

export default {
  name: "SectionPolizze",
  components: { LlInput },
  data() {
    return {
      inputConfig: {
        inputType: "text",
        inputName: "input-name",
        inputPlaceholder: "RCI BANQUE"
      }
    };
  }
};
</script>

<style lang="scss" scoped></style>
