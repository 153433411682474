<template>
  <mach1-layout>


    <!-- WARNING/ERROR MESSAGE -->
    <div v-show="errore != null" class="columns">
      <div class="column is-8">
        <div class="notification is-danger is-light">
          <button class="delete"></button>
          {{ errore }}
        </div>
      </div>
    </div>


    <!-- CAMPI UTENTE -->
    <div class="columns">
      <div class="column is-4">
        <ll-input :config="{inputPlaceholder: 'Cognome e Nome', floatingLabel: true, disabled: true}" v-model="polizza.cliente" />
      </div>
      <div class="column is-4">
        <ll-input :config="{inputPlaceholder: 'Email', floatingLabel: true}" v-model.trim="polizza.email" />
      </div>
    </div>
    <div class="columns">
      <div class="column is-4">
        <ll-input :config="{inputPlaceholder: 'Cellulare', floatingLabel: true}" v-model.trim="polizza.cellulare" />
      </div>
      <div class="column is-4">
        <ll-input :config="{inputPlaceholder: 'Telefono', floatingLabel: true}" v-model="polizza.telefono" />
      </div>
    </div>
    <div class="columns">
      <div class="column is-4">
        <ll-input :config="{inputPlaceholder: 'Indirizzo', floatingLabel: true, disabled: true}" v-model="polizza.indirizzo" />
      </div>
      <div class="column is-4">
        <ll-input :config="{inputPlaceholder: 'Località', floatingLabel: true, disabled: true}" v-model="polizza.citta" />
      </div>
    </div>
    <div class="columns">
      <div class="column is-4">
        <ll-input :config="{inputPlaceholder: 'Cap', floatingLabel: true, disabled: true}" v-model="polizza.cap" />
      </div>
      <div class="column is-4">
        <ll-input :config="{inputPlaceholder: 'Provincia', floatingLabel: true, disabled: true}" v-model="polizza.provincia" />
      </div>
    </div>
    <div class="columns">
      <div class="column is-4">
        <ll-input :config="{inputPlaceholder: 'Numero polizza', floatingLabel: true, disabled: true}" v-model="polizza.numero_polizza" />
      </div>
      <div class="column is-4">
        <ll-input :config="{inputPlaceholder: 'Scadenza', floatingLabel: true, disabled: true}" v-model="polizza.scadenza" />
      </div>
    </div>
    <div class="columns">
      <div class="column is-4">
        <ll-input :config="{inputPlaceholder: 'Targa', floatingLabel: true, disabled: true}" v-model="polizza.targa" />
      </div>
      <div class="column is-4">
        <ll-input :config="{inputPlaceholder: 'Telaio', floatingLabel: true, disabled: true}" v-model="polizza.telaio" />
      </div>
    </div>
    <div class="columns">
      <div class="column is-4">
        <ll-input :config="{inputPlaceholder: 'Marca', floatingLabel: true, disabled: true}" v-model="polizza.marca" />
      </div>
      <div class="column is-4">
        <ll-input :config="{inputPlaceholder: 'Modello', floatingLabel: true, disabled: true}" v-model="polizza.modello" />
      </div>
    </div>

    
    <!-- DISCLAIMER -->
    <div class="columns">
      <div class="column is-8">
        <span style="font-size: 12px; color: grey">Eventuali richieste di modifiche relative all'indirizzo di residenza o al veicolo, dovranno essere inviate a crm@wefox.it</span>
      </div>
    </div>


    <!-- SCEGLI PACCHETTO -->
    <div class="columns">
      <div class="column is-8">
        <p>Scegli il pacchetto di tuo interesse:</p>
      </div>
    </div>


    <!-- CVT + PAI -->
    <div v-if="tariffa_pai" class="columns">
      <div v-for="(pacchetto, i) in pacchetti" :key="i" class="column is-4">
        <div v-if="tariffa_pai" @click="selectBox(i, pacchetto)" class="tooltip product-box" :style="{ borderColor: selected_pacchetto_index == i ? 'darkorange' : '#BBBBBB' }">
          <span v-if="pacchetto_info(pacchetto.nome) != null" class="tooltiptext">{{ pacchetto_info(pacchetto.nome) }}</span>
          <table>
          <tr>
            <td style="padding-right: 4px; width: 20px"><span v-if="selected_pacchetto_index == i"><input type="checkbox" checked disabled></span></td>
            <td><b>{{ pacchetto.nome }}</b></td>
          </tr>
          <tr>
            <td></td>
            <td>Premio {{ pacchetto.premio }} €</td>
          </tr>
          <tr>
          </tr>
          <tr>
            <td style="padding-right: 4px; width: 20px"><span v-if="selected_pacchetto_index == i"><input type="checkbox" @click="selectPai()" :checked="(checkbox_pai)"></span></td>
            <td><b>PAI</b></td>
          </tr>
          <tr>
            <td></td>
            <td>Premio {{ tariffa_pai.pcl }} €</td>
          </tr>
          </table>
        </div>
      </div>
    </div>


    <!-- PAI -->
    <div v-if="tariffa_pai" class="columns">
      <div class="column is-8">
        <b>Oppure seleziona solo la tua polizza PAI</b>
      </div>
    </div>

    <div v-if="tariffa_pai" class="columns">
      <div class="column is-4">
        <div @click="selectPaiBox()" class="tooltip product-box" :style="{ borderColor: selected_pacchetto_index == -1 ? 'darkorange' : '#BBBBBB' }">
          <table>
          <tr>
            <td style="padding-right: 4px; width: 20px"><span v-if="selected_pacchetto_index == -1"><input type="checkbox" checked disabled></span></td>
            <td><b>PAI</b></td>
          </tr>
          <tr>
            <td></td>
            <td>Premio {{ tariffa_pai.pcl }} €</td>
          </tr>
          </table>
        </div>
      </div>
    </div>


    <!-- CVT -->
    <div v-if="!tariffa_pai" class="columns">
      <div v-for="(pacchetto, i) in pacchetti" :key="i" class="column is-4">
        <div @click="selectBox(i, pacchetto)" class="tooltip product-box" :style="{ borderColor: selected_pacchetto_index == i ? 'darkorange' : '#BBBBBB' }">
          <span v-if="pacchetto_info(pacchetto.nome) != null" class="tooltiptext">{{ pacchetto_info(pacchetto.nome) }}</span>
          <b>{{ pacchetto.nome }}</b><br>
          Premio {{ pacchetto.premio }} €
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column is-8 has-text-right">
        PREMIO TOTALE: <b>{{ totale }} €</b>
      </div>
    </div>


    <garanzie></garanzie>
    <div class="columns">
      <div class="column is-8">
        <i style="font-size: 13px">"{{ polizza.desc }}"</i>
      </div>
    </div>
    <div class="columns">
      <div class="column is-8">
            <button @click="annulla" class="button is-warning is-pulled-left is-outlined">Annulla</button>
            <button @click="rinnova" :disabled="errore != null || (tariffa_pai && premio_totale2 == 0) || polizza.email == '' || polizza.cellulare == ''" class="button is-warning is-pulled-right">Rinnova adesso</button>
      </div>
    </div>


    <!-- POP UP ADEGUATEZZA -->
    <div class="modal" :class="{ 'is-active': show_modal }">
      <div class="modal-background"></div>
        <div class="modal-content" style="background-color: white; font-weight: normal; padding: 40px;">

          <div class="column is-12">
            <div class="logo-popup">
              <img v-bind:src="this.url_logo_marchio" />
            </div>
          </div>

          <!-- DOMANDA 1 -->
          <p style="padding-top: 20px">
            {{ domanda_1 }}          
          </p>
          <div class="control">
            <label class="radio"><input type="radio" name="answer1" value="true" v-model="answer1"> Si</label>
            <label class="radio"><input type="radio" name="answer1" value="false" v-model="answer1"> No</label>
          </div>

          <!-- DOMANDA 2 -->
          <p style="padding-top: 20px">
            {{ domanda_2 }}          
          </p>
          <div class="control">
            <label class="radio"><input type="radio" name="answer2" value="true" v-model="answer2"> Si</label>
            <label class="radio"><input type="radio" name="answer2" value="false" v-model="answer2"> No</label>
          </div>

          <!-- DOMANDA 3 -->
          <p style="padding-top: 20px">
            {{ domanda_3 }}          
          </p>
          <div class="control">
            <label class="radio"><input type="radio" name="answer3" value="true" v-model="answer3"> Si</label>
            <label class="radio"><input type="radio" name="answer3" value="false" v-model="answer3"> No</label>
          </div>

          <!-- DOMANDA 4 -->
          <p style="padding-top: 20px">
            {{ domanda_4 }}          
          </p>
          <div class="control">
            <label class="radio"><input type="radio" name="answer4" value="true" v-model="answer4"> Si</label>
            <label class="radio"><input type="radio" name="answer4" value="false" v-model="answer4"> No</label>
            <p v-if="documento_cartaceo" style="padding-top:10px; padding-left:20px; font-size:80%">
              per ricevere la documentazione in formato cartaceo scrivere e-mail a crm@wefox.it</p>
          </div>

          <!-- DOMANDA 5 -->
          <p style="padding-top: 40px;font-weight:bold;">
            Hai preso visione dei documenti in seguito</p>
          <div class="control">
            <label class="radio"><input type="radio" name="answer5" value="true" v-model="answer5"> Si</label>
            <label class="radio"><input type="radio" name="answer5" value="false" v-model="answer5"> No</label>
          </div>
          <div class="content">
            <ul>
                <li><a :href="pdf_fascicolo" target="_blank">Set informativo</a></li>
                <li><a :href="pdf_informativa" target="_blank">Informativa precontrattuale</a> (Allegato3, Allegato 4, Allegato 4 ter)</li>
            </ul>
            <p>
            Procedendo confermi la correttezza dei tuoi dati anagrafici e di voler quindi procedere con l'acquisto della polizza che ti verrà addebitata.</p>
          </div>

          <!-- DOMANDA 6 -->
          <div v-if="ultima_domanda" style="padding-top: 30px">
            <p style="font-weight:bold;">
              Alla luce delle informazioni fornite, ritieni che il prodotto selezionato sia coerente con le tue esigenze?</p>
            <div class="control">
              <label class="radio"><input type="radio" name="answer6" value="true" v-model="answer6"> Si</label>
              <label class="radio"><input type="radio" name="answer6" value="false" v-model="answer6"> No</label>
            </div>
          </div>

          <!-- CONFERMA / ANNULLA -->
          <div style="padding-top: 20px">
            <button @click="annullaAcquisto" class="button is-warning is-pulled-left is-outlined">Annulla</button>
            <button @click="conferma" :disabled="conferma_disabled" class="button is-warning is-pulled-right" :class="{ 'is-loading': conferma_loading }">Conferma</button>
          </div>
        </div>
        <button class="modal-close is-large" aria-label="close"></button>
      </div>
  </mach1-layout>
</template>

<script>
  import Mach1Layout from "@/components/layouts/Mach1Layout.vue";
  import LlInput from '@wsi/ui-kit/LlInput'
  import Garanzie from "@/components/Garanzie.vue";

  export default {
    components: {
      Mach1Layout,
      LlInput,
      Garanzie
    },

    data () {
      return {
        errore: null,
        polizza: {},
        pai_detail: null,
        show_modal: false,
        conferma_loading: false,
        conferma_done: false,
        answer1: "false",
        answer2: "false",
        answer3: "false",
        answer4: "true",
        answer5: "false",
        answer6: "false",
        select_pacchetto: true,
        checkbox_pai: true,
        selected_pacchetto_index: 0,
        selected_pacchetto: null,
        selected_pai: false,
        select_index: 0,
        select_index2: 0,
        pdf_fascicolo: null,
        pdf_informativa: null
      }
    },

    computed: {
      documento_cartaceo() {
        return (
          this.answer4 == 'false')
      },
      ultima_domanda() {
        return (
          this.answer1 == 'true' && 
          this.answer2 == 'true' && 
          this.answer3 == 'true' &&
          this.answer5 == 'true')
      },
      conferma_disabled() {
        return !(
          this.answer1 == 'true' && 
          this.answer2 == 'true' && 
          this.answer3 == 'true' &&
          this.answer5 == 'true' &&
          this.answer6 == 'true')
      },
      tariffa_pai() {
        if(this.pai_detail != null)
          return this.pai_detail 
        return null;
      },
      pacchetti() {
        return this.polizza.pacchetti;
      },
      totale() {
        if(this.selected_pacchetto != null || this.pai_detail != null){
          if(this.selected_pai)
            return this.tariffa_pai.pcl;
          else{
            if(this.checkbox_pai && this.tariffa_pai != null)
              return this.selected_pacchetto.premio + this.tariffa_pai.pcl
            return this.selected_pacchetto.premio
          }
        }return null
      },
      route_params() {
        console.log(this.$route.params.compagnia.length)
        return {
          cf_piva: this.$route.params.compagnia.length > 3 ? atob(this.$route.params.cf_piva) : this.$route.params.cf_piva,
          compagnia: this.$route.params.compagnia.length > 3 ? atob(this.$route.params.compagnia) : this.$route.params.compagnia,
          numero_polizza: this.$route.params.compagnia.length > 3 ? atob(this.$route.params.numero_polizza) : this.$route.params.numero_polizza
        }
      }
    },

    methods: {
      selectBox(i, pacchetto){
        this.selected_pacchetto = pacchetto
        this.selected_pacchetto_index = i
        this.selected_pai = false
      },
      selectPai() {
        this.checkbox_pai = !this.checkbox_pai
      },
      selectPaiBox() {
        this.selected_pacchetto_index = -1
        this.selected_pacchetto = null
        this.selected_pai = true
      },
      annulla() {
        this.$router.go(-1)
      },
      rinnova() {
        this.set_questions();
        this.show_modal = true
        this.load_pdf_url()
      },
      async load_pdf_url() {
        
        let pacchetto = ''
        let params = this.route_params
        let compagnia = params.compagnia

        if (this.selected_pacchetto!= null && this.tariffa_pai != null && this.checkbox_pai)
          pacchetto = this.selected_pacchetto.nome + '+PAI'
        else if (this.selected_pai && this.tariffa_pai != null){
          pacchetto = 'PAI'
          compagnia = 'A21'
        }
        else if (this.selected_pacchetto!= null)
          pacchetto = this.selected_pacchetto.nome
        
        let response = await this.$http.get(`/api/v1/getPdf/${compagnia}/${pacchetto}`)
        this.pdf_fascicolo = response.data.fascicolo
        this.pdf_informativa = response.data.informativa
      },
      annullaAcquisto() {
        this.show_modal = false
        if (this.conferma_done) {
          this.$router.go()
        }
      },
      set_questions(){
        if(this.selected_pai){
          this.domanda_1 = "Persegui obiettivi di protezione assicurativa della tua persona a seguito di eventuale decesso, ricovero ospedaliero e terapia fisica causato da infortunio durante la circolazione stradale?";
          this.domanda_2 = "Valuti che il premio proposto sia in linea con le tue capacità di spesa?";
          this.domanda_3 = "Confermi che la durata proposta e le caratteristiche delle coperture assicurative (ivi comprese massimali, scoperti, franchigie ed esclusioni) siano in linea con le tue aspettative?";
          this.domanda_4 = "Confermi di voler ricevere la documentazione via mail con supporto durevole?";
        }else if(this.checkbox_pai && this.tariffa_pai != null){
          this.domanda_1 = "Persegui obiettivi di protezione assicurativa per eventuali danni, totali o parziali, subiti dal tuo veicolo e in caso di tutela della tua persona a seguito di eventuale decesso, ricovero ospedaliero e terapia fisica causato da infortunio durante la circolazione stradale?";
          this.domanda_2 = "Valuti che il premio proposto sia in linea con le tue capacità di spesa?";
          this.domanda_3 = "Confermi che la durata proposta e le caratteristiche delle coperture assicurative (ivi comprese massimali, scoperti, franchigie ed esclusioni) siano in linea con le tue aspettative?";
          this.domanda_4 = "Confermi di voler ricevere la documentazione via mail con supporto durevole?";
        }else{
          this.domanda_1 = "Persegui obiettivi di protezione assicurativa per eventuali danni per danno totale o parziale subiti dal tuo veicolo?";
          this.domanda_2 = "Valuti che il premio proposto sia in linea con le tue proprie capacità di spesa?";
          this.domanda_3 = "Confermi che la durata proposta e le caratteristiche delle coperture assicurative (ivi comprese massimali, scoperti, franchigie, ed esclusioni) siano in linea con le tue aspettative?";
          this.domanda_4 = "Confermi di voler ricevere la documentazione via mail con supporto durevole?";
        }
      },
      async conferma() {
        this.conferma_loading = true

        /* DOWNLOAD AUTOMATICO
        let download_fascicolo = this.pdf_fascicolo.replace('pdf', 'file-download/pdf')
        let download_informativa = this.pdf_informativa.replace('pdf', 'file-download/pdf') 
        */
                
        let params = this.route_params //this.$route.params

        // last update
        let email = this.polizza.email
        let cellulare = this.polizza.cellulare
        let telefono = this.polizza.telefono

        // reset polizza
        this.polizza = this.$store.state.polizza
        
        // merge updates
        this.polizza.email = email
        this.polizza.cellulare = cellulare
        this.polizza.telefono = telefono
        
        //set object
        let polizza = this.polizza

        if (this.tariffa_pai != null && this.checkbox_pai)
          polizza["pai_check"] = 1
        else
          polizza["pai_check"] = 0


        //API RINNOVA
        if (this.selected_pacchetto != null) {
          await this.$http.put(
            `/api/v1/user/${params.cf_piva}/polizza/${params.compagnia}/${polizza.polizza_id}/rinnova`, {
              "polizza" : this.polizza, 
              "pacchetto_rinnovato" : this.selected_pacchetto.nome,
              'pcl': this.selected_pacchetto.premio,
              'rinnovo' : {
                'cliente': this.polizza.cliente,
                'numero': this.polizza.numero_polizza,
                'compagnia': params.compagnia,
                'scadenza': this.polizza.scadenza,
                'invio_documenti': (this.answer4 === 'true'),
                'email': this.polizza.email
              }
            }
          )
        }
        

        //API RINNOVA PAI
        if (this.tariffa_pai != null && this.checkbox_pai || this.selected_pai) {
          await this.$http.put(
            `/api/v1/user/${params.cf_piva}/polizza/A21/${this.pai_detail.idpolizze}/rinnova`, {
              "pacchetto_rinnovato" : 'PAI',
              'pcl': this.pai_detail.pcl,
              'rinnovo' : {
                'cliente': this.pai_detail.cliente,
                'numero': this.pai_detail.numero,
                'compagnia': 'A21',
                'scadenza': this.pai_detail.scadenza,
                'invio_documenti': (this.answer4 === 'true'),
                'email': this.polizza.email,
              }
            }
          )
        }        
        

        this.conferma_loading = false
        this.conferma_done = true
        window.open(`https://pagamenti.mach-1.it/nais/login.php?codice=${params.cf_piva}&polizza=${polizza.numero_polizza}`, "_blank")
        
        /* DOWNLOAD AUTOMATICO
        window.open(download_fascicolo)
        window.open(download_informativa) 
        */
      },
      pacchetto_info(nome) {
        if (nome.indexOf("FIRST TRIP") > -1) {
          return "La polizza copre il veicolo in caso di furto, sia totale che parziale o anche tentato, incendio e rapina";
        } else if (nome.indexOf("BUSINESS TOUR") > -1) {
          return "La polizza copre il caso di furto, sia totale che parziale o anche tentato, incendio e rapina, atti vandalici, eventi naturali e rottura accidentale dei cristalli";
        } else if (nome.indexOf("CLUB VOYAGE COLLISIONE") > -1) {
          return "La polizza copre il caso di furto, sia totale che parziale o anche tentato, incendio e rapina, atti vandalici, eventi naturali, rottura accidentale dei cristalli e danni a seguito collisione tra veicoli";
        } else if (nome.indexOf("CLUB VOYAGE KASKO") > -1) {
          return "La polizza copre il caso di furto, sia totale che parziale o anche tentato, incendio e rapina, atti vandalici, eventi naturali, rottura accidentale dei cristalli e kasko"
        }
        return null;
      }
    },


    async mounted() {

      //get polizza from RINNOVI-API
      await this.$store.dispatch('loadPolizza', this.route_params)

      //api error
      if (this.$store.state.polizza.errore != null) {
        this.errore = this.$store.state.polizza.errore
        return
      }

      //pacchetti error
      if (this.polizza.pacchetti != null && this.polizza.pacchetti.length > 0) {
        this.errore = "polizza senza pacchetti"
        return
      }

      //set polizza object
      this.polizza = this.$store.state.polizza

      //select first pacchetto by default
      this.selected_pacchetto = this.polizza.pacchetti[0]


      //get pai detail if polizza has pai
      if (this.polizza.tariffe_aggiuntive != null)
        this.pai_detail = this.polizza.pai_detail

    },



    created() {
      let compagnia = this.$route.params.compagnia.length > 3 ? atob(this.$route.params.compagnia) : this.$route.params.compagnia;
      this.url_logo_marchio = require('@/assets/images/' + compagnia + '_small.png')
    }
  }
</script>

<style lang="scss">

// https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_tooltip

.tooltip {
  position: relative;
  // display: inline-block;
  // border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 300px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.logo-popup{
  text-align: right;
}

.logo-popup img{
  width: 200px;
  height: auto;
}

.product-box {
  background-color: #f5f5f5; 
  border: 1px solid #BBBBBB; 
  padding: 10px;
}

</style>