<template>
  <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>
  <div class="columns is-multiline is-centered">
    <div class="column is-10">

      <!-- LOGO -->
      <div class="columns" >
        <div class="column is-4">
          <div class="logo-img">
            <img src="@/assets/images/A08_brand.png" />
          </div>
        </div>

        <div class="column" style="text-align:right; padding 10px;">
          <ul>
            <li class="main_li"><b>CONTATTI</b>
              <ul class="dropdown" aria-label="submenu" style="text-align: center;">
                <li style="padding:30px;">
                  <i class="far fa-phone"></i> &nbsp; 02 006 800 57<hr class="my_hr">
                  <i class="fal fa-phone-plus"></i> &nbsp; 800 17 80 45<hr class="my_hr">
                  <i class="fal fa-envelope"></i> &nbsp; vwfs@wefox.it <hr class="my_hr">
                </li>
              </ul>
            </li>
          </ul>
        </div> 

      </div>

      <!-- TITLE POLIZZA -->
      <div class="columns" style="margin-top:100px">
        <div class="column">
          <t style="font-style:italic">
            In caso di reclamo,  il Cliente potrà utilizzare alternativamente uno dei seguenti canali:
            <br>
            <br>
              - Indirizzo: Via Privata Grosio, 10/4 - 20151 , Milano - Alla Cortese Attenzione Uff. Customer Service Insurance<br>
              - Tel.: +39 02 330271<br>
              - E-mail: assicurazioni@vwfs.com<br>
              - PEC: volkswagenfinancialservices@unapec<br>
            <br>
            <br>
            Il Cliente potrà altresì avvalersi di altri eventuali sistemi alternativi di 
            risoluzione delle controversie previsti dalla normativa vigente. In particolare:
            <br>
            <br>
            (i) mediazione finalizzata alla conciliazione, per qualsiasi controversia civile o commerciale 
            vertente su diritti disponibili, ai sensi del d.Lgs. n. 28 del 4 marzo 2010 (per avviarla, 
            occorre depositare apposita istanza presso un organismo di mediazione, 
            il cui registro è disponibile sul sito del Ministero della Giustizia, www.giustizia.it, 
            nel luogo del Giudice territorialmente competente);
            <br>
            <br>
            (ii) negoziazione assistita finalizzata alla composizione bonaria della lite 
            (il Contraente/Aderente può attivare tale procedura rivolgendosi al proprio avvocato, 
            ai sensi del D.L. n. 132, 12 settembre 2014, e, in tal caso, 
            le parti sottoscrivono un accordo con cui si impegnano a cooperare in buona fede 
            e con lealtà per risolvere in via amichevole la controversia entro un termine concordato.
          </t>
        </div>
      </div>
    </div>
  </div>

  <!-- FOOTER -->
  <div class="my_footer">
    <p>wefox MGA s.r.l. - Via Roberto Bracco, 6 - 20159 Milano - TEL. 02 30465068
FAX 02 62694254 - Email PEC: wefoxmga@registerpec.it- CCIAA Milano -
REA MI 1908726 - C.F., P. IVA e Reg. Imprese Milano 06680830962 -
Capitale sociale 100.000 EURO - Registro Unico Intermediari A000317603 -
iscritta dal 15 settembre 2009 e sottoposta alla vigilanza dell'IVASS - I dati
sono consultabili nel Registro RUI al seguente link: <a href="https://servizi.ivass.it/RuirPubblica/" targt="_blank">https://servizi.ivass.it/RuirPubblica/</a> - 
Banco Posta Sede di Milano, Piazza Cordusio, 1</p>
    <hr>
    <p>Volkswagen Financial Services S.p.A. è un intermediario assicurativo iscritto al Registro Unico degli Intermediari
       assicurativi e riassicurativi (RUI), in data 12.02.2019, N. D000621181; 
       sede legale/operativa: Via Privata Grosio, 10/4 - 20151 Milano; telefono 02 330271; 
       indirizzo di posta elettronica o PEC volkswagenfinancialservices@unapec.it.; 
       soggetto alla diretta Vigilanza di IVASS (relativamente all’attività di distribuzione assicurativa).
      Si segnala che gli estremi identificativi e di iscrizione sopra indicati 
      possono essere verificati consultando il Registro Unico Intermediari (RUI), 
      Sez. D, disponibile sul sito internet dell’IVASS (<a href="https://www.ivass.it/" targt="_blank">www.ivass.it</a>).</p>
  </div>


</template>

<script>

  export default {
    components: {},

    data () {
      return {
        errore: null,
        polizza: {},
        pai_detail: null,
        show_modal: false,
        conferma_loading: false,
        conferma_done: false,
        answer1: "false",
        answer2: "false",
        answer3: "false",
        answer4: "true",
        answer5: "false",
        answer6: "false",
        select_pacchetto: true,
        checkbox_pai: true,
        selected_pacchetto_index: 0,
        selected_pacchetto: null,
        selected_pai: false,
        select_index: 0,
        select_index2: 0,
        pdf_fascicolo: null,
        pdf_informativa: null
      }
    },

    computed: {
      documento_cartaceo() {
        return (
          this.answer4 == 'false')
      },
      ultima_domanda() {
        return (
          this.answer1 == 'true' && 
          this.answer2 == 'true' && 
          this.answer3 == 'true' &&
          this.answer5 == 'true')
      },
      conferma_disabled() {
        return !(
          this.answer1 == 'true' && 
          this.answer2 == 'true' && 
          this.answer3 == 'true' &&
          this.answer5 == 'true' &&
          this.answer6 == 'true')
      },
      tariffa_pai() {
        if(this.pai_detail != null)
          return this.pai_detail 
        return null;
      },
      pacchetti() {
        return this.polizza.pacchetti;
      },
      totale() {
        if(this.selected_pacchetto != null || this.pai_detail != null){
          if(this.selected_pai)
            return this.tariffa_pai.pcl;
          else{
            if(this.checkbox_pai && this.tariffa_pai != null)
              return this.selected_pacchetto.premio + this.tariffa_pai.pcl
            return this.selected_pacchetto.premio
          }
        }return null
      },
      route_params() {
        console.log(this.$route.params.compagnia.length)
        return {
          cf_piva: this.$route.params.compagnia.length > 3 ? atob(this.$route.params.cf_piva) : this.$route.params.cf_piva,
          compagnia: this.$route.params.compagnia.length > 3 ? atob(this.$route.params.compagnia) : this.$route.params.compagnia,
          numero_polizza: this.$route.params.compagnia.length > 3 ? atob(this.$route.params.numero_polizza) : this.$route.params.numero_polizza
        }
      }
    },

    methods: {
      selectBox(i, pacchetto){
        this.selected_pacchetto = pacchetto
        this.selected_pacchetto_index = i
        this.selected_pai = false
      },
      selectPai() {
        this.checkbox_pai = !this.checkbox_pai
      },
      selectPaiBox() {
        this.selected_pacchetto_index = -1
        this.selected_pacchetto = null
        this.selected_pai = true
      },
      annulla() {
        this.$router.go(-1)
      },
      rinnova() {
        this.set_questions();
        this.show_modal = true
        this.load_pdf_url()
      },
      async load_pdf_url() {
        
        let pacchetto = ''
        let params = this.route_params
        let compagnia = params.compagnia

        if (this.selected_pacchetto!= null && this.tariffa_pai != null && this.checkbox_pai)
          pacchetto = this.selected_pacchetto.nome + '+PAI'
        else if (this.selected_pai && this.tariffa_pai != null){
          pacchetto = 'PAI'
          compagnia = 'A21'
        }
        else if (this.selected_pacchetto!= null)
          pacchetto = this.selected_pacchetto.nome
        
        let response = await this.$http.get(`/api/v1/getPdf/${compagnia}/${pacchetto}`)
        this.pdf_fascicolo = response.data.fascicolo
        this.pdf_informativa = response.data.informativa
      },
      annullaAcquisto() {
        this.show_modal = false
        
        if (this.conferma_done) {
          this.$router.go()
        }
      },
      set_questions(){
        if(this.selected_pai){
          this.domanda_1 = "Persegui obiettivi di protezione assicurativa della tua persona a seguito di eventuale decesso, ricovero ospedaliero e terapia fisica causato da infortunio durante la circolazione stradale?";
          this.domanda_2 = "Valuti che il premio proposto sia in linea con le tue capacità di spesa?";
          this.domanda_3 = "Confermi che la durata proposta e le caratteristiche delle coperture assicurative (ivi comprese massimali, scoperti, franchigie ed esclusioni) siano in linea con le tue aspettative?";
          this.domanda_4 = "Confermi di voler ricevere la documentazione via mail con supporto durevole?";
        }else if(this.checkbox_pai && this.tariffa_pai != null){
          this.domanda_1 = "Persegui obiettivi di protezione assicurativa per eventuali danni, totali o parziali, subiti dal tuo veicolo e in caso di tutela della tua persona a seguito di eventuale decesso, ricovero ospedaliero e terapia fisica causato da infortunio durante la circolazione stradale?";
          this.domanda_2 = "Valuti che il premio proposto sia in linea con le tue capacità di spesa?";
          this.domanda_3 = "Confermi che la durata proposta e le caratteristiche delle coperture assicurative (ivi comprese massimali, scoperti, franchigie ed esclusioni) siano in linea con le tue aspettative?";
          this.domanda_4 = "Confermi di voler ricevere la documentazione via mail con supporto durevole?";
        }else{
          this.domanda_1 = "Persegui obiettivi di protezione assicurativa per eventuali danni per danno totale o parziale subiti dal tuo veicolo?";
          this.domanda_2 = "Valuti che il premio proposto sia in linea con le tue proprie capacità di spesa?";
          this.domanda_3 = "Confermi che la durata proposta e le caratteristiche delle coperture assicurative (ivi comprese massimali, scoperti, franchigie, ed esclusioni) siano in linea con le tue aspettative?";
          this.domanda_4 = "Confermi di voler ricevere la documentazione via mail con supporto durevole?";
        }
      },
      async conferma() {
        this.conferma_loading = true

        /* DOWNLOAD AUTOMATICO
        let download_fascicolo = this.pdf_fascicolo.replace('pdf', 'file-download/pdf')
        let download_informativa = this.pdf_informativa.replace('pdf', 'file-download/pdf') 
        */
                
        let params = this.route_params //this.$route.params

        // last update
        let email = this.polizza.email
        let cellulare = this.polizza.cellulare
        let telefono = this.polizza.telefono
        console.log(`conferma - email: ${email}`)
        console.log(`conferma - cellulare: ${cellulare}`)
        console.log(`conferma - telefono: ${telefono}`)

        this.polizza = this.$store.state.polizza
        
        // merge
        this.polizza.email = email
        this.polizza.cellulare = cellulare
        this.polizza.telefono = telefono
        
        let polizza = this.polizza

        if (this.tariffa_pai != null && this.checkbox_pai)
          polizza["pai_check"] = 1
        else
          polizza["pai_check"] = 0


        //API RINNOVA
        if (this.selected_pacchetto != null) {
          await this.$http.put(
            `/api/v1/user/${params.cf_piva}/polizza/${params.compagnia}/${polizza.polizza_id}/rinnova`, {
              "polizza" : this.polizza, 
              "pacchetto_rinnovato" : this.selected_pacchetto.nome,
              'pcl': this.selected_pacchetto.premio,
              'rinnovo' : {
                'cliente': this.polizza.cliente,
                'numero': this.polizza.numero_polizza,
                'compagnia': params.compagnia,
                'scadenza': this.polizza.scadenza,
                'invio_documenti': (this.answer4 === 'true'),
                'email': this.polizza.email,
                'da_email': true
              }
            }
          )
        }


        //API RINNOVA PAI
        if (this.tariffa_pai != null && this.checkbox_pai || this.selected_pai) {
          await this.$http.put(
            `/api/v1/user/${params.cf_piva}/polizza/A21/${this.pai_detail.idpolizze}/rinnova`, {
              "pacchetto_rinnovato" : 'PAI',
              'pcl': this.pai_detail.pcl,
              'rinnovo' : {
                'cliente': this.pai_detail.cliente,
                'numero': this.pai_detail.numero,
                'compagnia': 'A21',
                'scadenza': this.pai_detail.scadenza,
                'invio_documenti': (this.answer4 === 'true'),
                'email': this.polizza.email,
                'da_email': true
              }
            }
          )
        }  
            
        
        this.conferma_loading = false
        this.conferma_done = true
        console.log(`https://pagamenti.mach-1.it/nais/login.php?codice=${params.cf_piva}&polizza=${polizza.numero_polizza}`)
        window.open(`https://pagamenti.mach-1.it/nais/login.php?codice=${params.cf_piva}&polizza=${polizza.numero_polizza}`, "_blank")
       
        /* DOWNLOAD AUTOMATICO
        window.open(download_fascicolo)
        window.open(download_informativa) 
        */
      },
      pacchetto_info(nome) {
        if (nome.indexOf("FIRST TRIP") > -1) {
          return "La polizza copre il veicolo in caso di furto, sia totale che parziale o anche tentato, incendio e rapina";
        } else if (nome.indexOf("BUSINESS TOUR") > -1) {
          return "La polizza copre il caso di furto, sia totale che parziale o anche tentato, incendio e rapina, atti vandalici, eventi naturali e rottura accidentale dei cristalli";
        } else if (nome.indexOf("CLUB VOYAGE COLLISIONE") > -1) {
          return "La polizza copre il caso di furto, sia totale che parziale o anche tentato, incendio e rapina, atti vandalici, eventi naturali, rottura accidentale dei cristalli e danni a seguito collisione tra veicoli";
        } else if (nome.indexOf("CLUB VOYAGE KASKO") > -1) {
          return "La polizza copre il caso di furto, sia totale che parziale o anche tentato, incendio e rapina, atti vandalici, eventi naturali, rottura accidentale dei cristalli e kasko"
        }
        return null;
      }
    },

    async mounted() {

      //get polizza from RINNOVI-API
      await this.$store.dispatch('loadPolizza', this.route_params)

      //api error
      if (this.$store.state.polizza.errore != null) {
        this.errore = this.$store.state.polizza.errore
        return
      }

      //pacchetti error
      if (this.polizza.pacchetti != null && this.polizza.pacchetti.length > 0) {
        this.errore = "polizza senza pacchetti"
        return
      }

      //set polizza object
      this.polizza = this.$store.state.polizza

      //select first pacchetto by default
      this.selected_pacchetto = this.polizza.pacchetti[0]


      //get pai detail if polizza has pai
      if (this.polizza.tariffe_aggiuntive != null)
        this.pai_detail = this.polizza.pai_detail

    },

    created() {
      let compagnia = this.$route.params.compagnia.length > 3 ? atob(this.$route.params.compagnia) : this.$route.params.compagnia;
      this.url_logo_marchio = require('@/assets/images/' + compagnia + '_small.png')
    }
  }
</script>

<style lang="scss" scoped>

// https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_tooltip

.tooltip {
  position: relative;
  // display: inline-block;
  // border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 100%;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding:20px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.logo-popup{
  text-align: right;
}

.logo-popup img{
  width: 200px;
  height: auto;
}

.logo-img img {
    height: 175px;
    width: auto;
}

.my_label {
    display:block;
    font-size: 12px;
    color: rgba(76, 83, 86, 0.5);
}

.my_input {
    margin-top: 15px;
    display:block;
    font-size: 16px;
    color: #4C5356;
    border:none;
    border-bottom: 1px solid #4C5356;
    outline: none;
    width: 90%;
    padding-bottom: 7px;
 }

.my_input[readonly] {
  cursor: not-allowed;
}

.input_mini {
  width: 80%;
}

.my_title {
  font-size: 30px;
  color: #004666;
}

.my_blue_text {
  color: #004666;
  font-weight: bold;
}

.my_pack {
  padding: 20px;
  background: #F2F2F2;
  color: #004666;
  border: 1px solid #A8ADB3
}

.my_pack_selected {
  background: linear-gradient(249.26deg, #0082A0 0%, #004666 100%);
  color:white;
}

.my_selection_check {
  border:1px solid #000;
  height: 30px;
  width: 30px;
  background-color: #fff;border-radius: 16px;
  color: #004666;
  text-align: center;
  font-size: 20px;
}

.my_button {
  width: 100%;
  color: #004666 !important;
  border-radius: 0px !important;
  font-weight: bold;
}

.my_hr {
    border: none;
    height: 2px;
    color: #006384; /* old IE */
    background-color: #006384; /* Modern Browsers */
    padding: 0px;
    margin: 15px;    
}

li {
	color: #fff;
  background: #004767;
	display: block;
	float: right;
	padding: 15px 15px 0 15px;
	position: relative;
	text-decoration: none;
  transition-duration: 0.5s;
}

.main_li{
  color: #004767;
  background: #fff;
}

li:hover,
li:focus-within {
	cursor: pointer;
}

li:focus-within a {
  outline: none;
}

ul li ul {
  color: #fff;
	visibility: hidden;
  opacity: 0;
  min-width: 5rem;
	position: absolute;
  transition: all 0.5s ease;
  margin-top: 1rem;
	right: 0;
  display: none;
}

ul li:hover > ul,
ul li:focus-within > ul,
ul li ul:hover,
ul li ul:focus {
   visibility: visible;
   opacity: 1;
   display: block;
}

ul li ul li {
	clear: both;
  width: 130%;
}

.my_footer{
  margin-top:100px;
  padding: 20px;
  background-color: #004666;
  color:white;
  font-size: 14px;
}

.check_space{
  padding: 6px 0 0 0;  
  width: 40px;
}

input[type="checkbox"]:hover{
    cursor: pointer;
}

input[type="checkbox"]:checked {
  background-color: #fff;
}

input[type="checkbox"]:checked:after {
  content: '✔';
  color: #004666;
  font-size: 15px;
}

input[type="checkbox"][disabled] {
  color: #252829;
  background-color: rgb(187, 184, 184);
  cursor: not-allowed;
}

input[type="checkbox"]{
  background-color: #fff;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  width: 20px !important;
  height: 20px !important;
  appearance:none;
  border-radius:15px;
  box-shadow: none;
  font-size: 1em;
}

</style>