<template>
  <div>
    <h1 class="is-size-2">Components sample list</h1>
  </div>

  <!-- Row 1 -->
  <div class="columns">
    <div class="column is-2">
      <ll-box>
        <h1>This is a box</h1>
      </ll-box>
    </div>
    <div class="column is-2">
      <div class="bold">Icons</div>
      <ll-icon icon-name="alert" width="16"></ll-icon>
      <ll-icon icon-name="link-external" width="24"></ll-icon>
      <ll-icon icon-name="home" width="40"></ll-icon>
    </div>
    <div class="column is-2">
      <div class="bold">
        Buttons
      </div>
      <ll-button @click="test" button-text="Button" class-list="is-primary"></ll-button>
    </div>
    <div class="column is-2">
      <div class="bold">Treemenu</div>
      The navigation on the left
    </div>
    <div class="column is-4">
      <div class="bold">
        Notifications
      </div>
      <ll-notification colorBackground="is-info"
        >Lorem ipsum dolor sit amet, consectetur adipiscing elit lorem ipsum
        dolor.
      </ll-notification>
    </div>
  </div>
  <!-- Row 3 -->
  <div class="columns">
    <div class="column is-2">
      <div class="bold">Messages</div>
      <ll-message headerText="Small" messageSize="is-small">
        Text
      </ll-message>
      <ll-message colorBackground="is-link"> Text </ll-message>
    </div>
    <div class="column is-4">
      <div class="bold">
        Progress bars
      </div>

      <ll-progress-bar barValue="15"></ll-progress-bar>
      <ll-progress-bar barColor="is-success" barValue="45"></ll-progress-bar>
      <ll-progress-bar barColor="is-warning" barValue="60"></ll-progress-bar>
      <ll-progress-bar barColor="is-danger" barValue="75"></ll-progress-bar>
    </div>
    <div class="column is-2"></div>
    <div class="column is-2"></div>
    <div class="column is-4"></div>
  </div>
  <!-- Row 2 -->
</template>

<script>
import LlBox from "@wsi/ui-kit/LlBox";
import LlIcon from "@wsi/ui-kit/LlIcon";
import LlButton from "@wsi/ui-kit/LlButton";
import LlNotification from "@wsi/ui-kit/LlNotification";
import LlMessage from "@wsi/ui-kit/LlMessage";
import LlProgressBar from "@wsi/ui-kit/LlProgressBar";

export default {
  components: {
    LlBox,
    LlIcon,
    LlButton,
    LlNotification,
    LlMessage,
    LlProgressBar
  },
  methods: {
    test() {
      alert(1)
    }
  }
}
</script>

<style scoped>
.bold {
  font-weight: 700;
  font-size: 12px;
}
</style>
