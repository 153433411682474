<template>
  <test-layout>
        <div class="content">
            <b>
                Test cases
                <a href="https://www.figma.com/proto/nu7liDEiklquf4QN3LeEM6/UX%2FUI-Rinnovi-MACH1?node-id=89%3A176&viewport=843%2C310%2C0.1273123025894165&scaling=min-zoom">flow 1</a>
            </b>
            <ul>
                <li>
                  <router-link :to="{ name: 'Polizza', params: { cf_piva: 'BLLSRG56R20D869W', compagnia: 'A08', numero_polizza: '00000005620551'}}">Polizza VW</router-link> |
                  <router-link :to="{ name: 'Polizza', params: { cf_piva: 'FLBMGS50B01M202A', compagnia: 'A08', numero_polizza: '00000007608715'}}">Polizza VW</router-link> |
                  <router-link :to="{ name: 'Polizza', params: { cf_piva: 'QkFJR1BQNjlBNDlHMzM3Vw==', compagnia: 'QTA4', numero_polizza: 'MDAwMDAwMDY1NzkxNDk='}}">enc</router-link>
                </li>
            </ul>
            <ul>
                <li>
                  <router-link :to="{ name: 'PolizzaVW', params: { cf_piva: 'PTRFMC77B01H792N', compagnia: 'A08', numero_polizza: '00000006033079'}}">Polizza VW</router-link> |
                  <router-link :to="{ name: 'PolizzaVW', params: { cf_piva: 'QkFJR1BQNjlBNDlHMzM3Vw==', compagnia: 'QTA4', numero_polizza: 'MDAwMDAwMDY1NzkxNDk='}}">enc</router-link> (new design)
                </li>
            </ul>
            <ul>
                <li><router-link :to="{ name: 'Polizza', params: { cf_piva: 'sssccc91d62a783t', compagnia: 'A26', numero_polizza: '00000006018779'}}">Polizza DUCATI</router-link></li>
            </ul>
            <ul>
                <li><router-link :to="{ name: 'Polizza', params: { cf_piva: 'DLCNLS72C69G702H', compagnia: 'A57', numero_polizza: '10062630'}}">Polizza BMW</router-link></li>
            </ul>
            <ul>
                <li><router-link :to="{ name: 'Polizza', params: { cf_piva: 'MRNMHL77D05F839F', compagnia: 'A55', numero_polizza: 'O77457713'}}">Polizza OPEL</router-link></li>
            </ul>
            <ul>
                <li>
                  <router-link :to="{ name: 'PolizzaOPEL', params: { cf_piva: 'RSSNNN64S08L112V', compagnia: 'A55', numero_polizza: 'O78096051'}}">Polizza OPEL</router-link> (new design)
                </li>
            </ul>
        </div>
  </test-layout>
</template>

<script>
  import TestLayout from "@/components/layouts/TestLayout.vue";

  export default {
    components: {
      TestLayout
    },
  }
</script>
