import { createStore } from "vuex"
import axios from 'axios'

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL

const store = createStore({
    state: {
        polizza: null,
        count: 0,
        lista: []
    },
    getters: {
        getPolizza: (state) => (numero_polizza) => {
            return state.lista.filter(item => item.polizza.numero_polizza == numero_polizza)
        }
    },
    mutations: {
        increment(state) {
            state.count++
        },
        load(state, lista) {
            state.lista = lista
        },
        check(state, numero_polizza) {
            console.log(numero_polizza)
            state.lista.forEach((item) => {
                if (item.polizza.numero_polizza == numero_polizza) {
                    item.selected = !item.selected
                }
            });
        },
        setPolizza(state, polizza) {
            state.polizza = polizza
        },
        check_garanzia(state, codice) {
            let index = state.polizza.garanzie_selezionate.indexOf(codice)
            if (index > -1) {
                state.polizza.garanzie_selezionate.splice(index, 1)
            } else {
                state.polizza.garanzie_selezionate.push(codice)
            }
        }
    },
    actions: {
        async increment(context) {
            context.commit('increment')
        },
        async load(context, params) {
            if (context.state.lista.length == 0) {
                let response = await axios.get(`/api/v1/user/${params.cf_piva}/polizze`)
                console.log(response.data)
                    context.commit('load', response.data)
            }
        },
        async loadPolizza(context, params) {
            //console.log(context.state)
            if (context.state.polizza == null) {
                console.log('#######################' + JSON.stringify(context.state.polizza))
            }
            //console.log(context.state.polizza == null || params.numero_polizza == context.state.polizza.polizza.numero_polizza)
            if (context.state.polizza == null || params.numero_polizza != context.state.polizza.numero_polizza) {
                try {
                    // /////////////////////////////////////////////////////////////////////
                    // PATCH Rinnovi1.0 to verify if the renewal is on Rinnovi2.0
                    const appRoot = document.getElementById("app")
                    // /////////////////////////////////////////////////////////////////////
                    try {
                        appRoot.style.opacity = "0.8";

                        const responseV2 = await axios.get(`/api/v1/check_renewals/policy/${params.numero_polizza}`);
                        let url = responseV2.data.url;
                        if (url) {
                            window.location = url;
                            return
                        }
                    } catch (e) {
                        console.error('Not able to check on Rinnovi2.0')
                    }
                    appRoot.style.opacity = null;
                    // /////////////////////////////////////////////////////////////////////

                    let response = await axios.get(`/api/v1/user/${params.cf_piva}/polizza/${params.compagnia}/${params.numero_polizza}`)
                    context.commit('setPolizza', response.data)
                } catch (error) {
                    console.log("################# TRY-CATCH")
                    console.log(error)
                    console.log(error.message)
                    console.log(error.response)
                    console.log(error.response.status)
                    console.log(error.response.data)
                    console.log(error.response.data.message)

                    // 409 JSON
                    context.commit('setPolizza', {errore: error.response.data.message})

                    // 408 BODY

                    //context.commit('setPolizza', {errore: "Errore interno di sistema"})
                }
            }
        },
        async calcola(context, params) {
            console.log('#######################' + JSON.stringify(params))
            context.commit('check_garanzia', params.codice)

            let response = await axios.put(`/api/v1/user/${params.cf_piva}/polizza/${params.compagnia}/${params.numero_polizza}/calcola`, {
                polizza: context.state.polizza
            })
            
            console.log(response.data)
            context.commit('setPolizza', response.data.polizza)
        }
    }
})

export default store