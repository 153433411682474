<template>
  <nav class="section-nav">
    <ul class="link-tree">
      <li v-for="(item, i) in config.items" :key="i">
        <a :href="item.to" class="button-link"></a>{{ item.label }}
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "SectionNav",
  props: {
    config: {
      items: Object
    }
  }
};
</script>
