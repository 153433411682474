<template>
    <div v-if="garanzie.length > 0" class="columns">
      <div class="column is-8">
        <p>Scegli le garanzie di tuo interesse:</p>
      </div>
    </div>
    <div v-if="garanzie.length > 0" class="columns">
      <div class="column is-8">
        <table>
        <tr v-for="(garanzia, index) in garanzie" :key="index">
            <td style="padding: 4px">{{ garanzia.descrizione }}</td>
            <td style="padding: 4px">
                <div class="field">
                    <input :checked="garanzie_selezionate.indexOf(garanzia.codice) > -1" @change="check_garanzia(garanzia.codice)" :disabled="garanzia.readonly" type="checkbox" class="switch is-rounded is-success">
                </div>
            </td>
            <td style="padding: 4px; white-space: nowrap">€ {{ garanzia.pcl }}</td>
        </tr>
        <tr>
            <td style="text-align: right; padding: 4px" colspan="2"><b>Totale</b></td>
            <td style="padding: 4px; white-space: nowrap">€ {{ totale }}</td>
        </tr>
        </table>
      </div>
    </div>
</template>

<script>
export default {
    computed: {
        garanzie() {
            if (this.$store.state.polizza != null && this.$store.state.polizza.garanzie != null) {
                return this.$store.state.polizza.garanzie
            }
            return []
        },
        garanzie_selezionate() {
            if (this.$store.state.polizza != null && this.$store.state.polizza.garanzie_selezionate != null) {
                return this.$store.state.polizza.garanzie_selezionate
            }
            return []
        },
        totale() {
            /*const total = this.garanzie.reduce((total, garanzia) => {
                if (this.garanzie_selezionate.indexOf(garanzia.codice) > -1) {
                    return total + garanzia.pcl
                }
                return total + 0
            }, 0);
            return total*/

            if (this.$store.state.polizza != null) {
                return this.$store.state.polizza.pcl
            }
            return 0;
        }
    },
    methods: {
        async check_garanzia(codice) {
            console.log(codice)
            console.log(this.garanzie_selezionate)
            //this.$store.commit('check_garanzia', codice)
            await this.$store.dispatch('calcola', {
                cf_piva: this.$route.params.cf_piva,
                compagnia: this.$route.params.compagnia,
                numero_polizza: this.$route.params.numero_polizza,
                codice: codice
            })
        }
    },
    async mounted() {
        console.log("Garanzie")
    }
}
</script>
