<template>
    <section class="container">
        <div>
            <router-link to="/test">Test</router-link> |
            <router-link to="/about">About</router-link> |
            <router-link to="/components">Components</router-link> |
            <router-link :to="{ name: 'Polizza', params: { cf_piva: '03392091207', compagnia: 'A08', numero_polizza: '00000004830199'}}">Polizza</router-link> |
            <router-link :to="{ name: 'ListaPolizze', params: { cf_piva: 'GGNMRC73R03H501O'}}">Lista Polizze</router-link>
        </div>
        <hr>
        <slot></slot>
    </section>
</template>
