<template>
  <div class="box-contattaci">
    <h2 class="title">Contattaci</h2>

    <ll-select :config="selectConfig"></ll-select>

    <ul class="contatti-container">
      <li class="contatto" v-for="(contact, i) in config.contacts" :key="i">
        <ll-icon
          v-if="contact.iconName"
          :icon-name="contact.iconName"
        ></ll-icon>
        <div class="img-container" v-if="contact.imgPath">
          <img :src="contact.imgPath" alt="" />
        </div>

        <ul class="details">
          <li>
            {{ contact.label }}
            <br />
            {{ contact.details }}
          </li>
        </ul>
      </li>
    </ul>

    <ll-button
      button-text="Rinnova la tua polizza con un click"
      class-list="is-primary"
    ></ll-button>
  </div>
</template>

<script>
import LlSelect from "@wsi/ui-kit/LlSelect";
import LlIcon from "@wsi/ui-kit/LlIcon";
import LlButton from "@wsi/ui-kit/LlButton";

export default {
  name: "BoxContattaci",
  components: { LlSelect, LlIcon, LlButton },
  props: {
    config: {
      contacts: Array
    }
  },
  data() {
    return {
      selectConfig: {
        options: [
          {
            label: "Option 1"
          },
          {
            label: "Option 2"
          },
          {
            label: "Option 3"
          },
          {
            label: "Option 4"
          }
        ]
      }
    };
  },
  methods: {
    // getImagePath(contact) {
    //   return require(`../../assets/images/${contact.imgPath}`);
    // }
  }
};
</script>

<style lang="scss"></style>
